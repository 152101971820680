// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { cssBundleHref } from "@remix-run/css-bundle";
import { json } from "@remix-run/node";
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useLocation } from "@remix-run/react";
import stylesRoot from './css/index.css';
import { ThemeProvider, useTheme } from "./provider/theme-provider";
import { getThemeSession } from "./server/theme.server";
import Header from "./views/Header";
export const links = () => [...(cssBundleHref ? [{
  rel: "stylesheet",
  href: cssBundleHref
}] : []), {
  rel: "stylesheet",
  href: stylesRoot
}];
export const loader = async ({
  request,
  params
}) => {
  const themeSession = await getThemeSession(request);
  const data = {
    theme: themeSession.getTheme()
  };
  return json(data);
};
export function Core() {
  _s();
  const [theme] = useTheme();
  const {
    pathname
  } = useLocation();
  return <html className={theme ?? ""} lang="en-US">

      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preload" as="font" href="/_static/fonts/Montserrat-Light.woff2" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" as="font" href="/_static/fonts/Montserrat-LightItalic.woff2" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" as="font" href="/_static/fonts/Montserrat-Medium.woff2" type="font/woff2" crossOrigin="anonymous" />
        <link rel="icon" href="/_static/favicon.ico" />
        <link rel="manifest" href="/_static/manifest.json" />
        <meta property="og:image" content="https://wefrick.com/_static/icons/strawberry1024.png" />
        <meta property="og:image:secure_url" content="https://wefrick.com/_static/icons/strawberry1024.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="1024" />
        <meta property="og:image:alt" content="Icon of a strawberry" />
        <meta name="theme-color" content="#e5484d" />
        <meta name="msvalidate.01" content="6DCCF2846BD9A91B5A8764CF0F5C1E76" />
        <meta name="yandex-verification" content="d925384339661f7d" />

        <Meta />
        <Links />
      </head>
      <body className={pathname === '/' ? 'noo' : ''}>
      <Header />
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>;
}
_s(Core, "0nhw9wLZxYzcPVppVD/P/2tLqyY=", false, function () {
  return [useTheme, useLocation];
});
_c = Core;
export default function App() {
  _s2();
  const {
    theme
  } = useLoaderData();
  return <ThemeProvider specifiedTheme={theme}>

      <Core />

    </ThemeProvider>;
}
_s2(App, "VwrtovmFFVHcbBlIBqMOsw8npCE=", false, function () {
  return [useLoaderData];
});
_c2 = App;
var _c, _c2;
$RefreshReg$(_c, "Core");
$RefreshReg$(_c2, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;