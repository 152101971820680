// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/views/Header.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/views/Header.tsx");
  import.meta.hot.lastModified = "1702300426567.638";
}
// REMIX HMR END

import { NavLink, useLocation } from "@remix-run/react";
import { Theme, useTheme } from "~/provider/theme-provider";
import { StrawberrySVG } from "~/resources/icons/StrawberrySVG";
export default function Header() {
  _s();
  const [theme, setTheme] = useTheme();
  const {
    pathname
  } = useLocation();
  return <header>
            <nav className="navbx">
                {pathname !== '/' ? <NavLink className="strawberry_logo" to="/">
                    <StrawberrySVG />
                </NavLink> : <div />}

                <div className="right_side">

                    <NavLink className="nal" to="/welcome">
                        hello
                    </NavLink>


                    <NavLink className="nal" to="/work">
                        work
                    </NavLink>


                    <NavLink className="nal" to="/experiments">
                        experiments
                    </NavLink>

                    <button className="nal" type="button" onClick={() => setTheme(Theme.DARK === theme ? Theme.LIGHT : Theme.DARK)}>
                        {Theme.DARK === theme ? Theme.LIGHT : Theme.DARK}
                    </button>
                </div>

            </nav>


        </header>;
}
_s(Header, "zBYKkUSsmAuvsm1QcdmhR1gmRRg=", false, function () {
  return [useTheme, useLocation];
});
_c = Header;
var _c;
$RefreshReg$(_c, "Header");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;